import { HoverCard } from "@mantine/core";
import { CustomCellRendererProps } from "ag-grid-react";
import { CellValue, DetailedCellError, RawCellContent, SimpleCellAddress } from "hyperformula";
import moment from "moment";
import React from "react";
import { RowDataType, RowMetadata, RowStyle } from "src/classes/GridState";
import { excelColToColNumber, HoverInfo, HoverLabel } from "src/classes/RenderedDoc";
import { TabName } from "src/redux/reducers/types";
import InfoIcon from "@mui/icons-material/Info";

export interface HFCellRenderContext {
  editing: boolean;
  tab: TabName;
  headers: string[];
  currentCol: string | null;
  currentRow: number | null;
  sheetId: number;
  rawSheetData: Record<string, RawCellContent[][]>;
  calculatedSheetData: Record<string, CellValue[][]>;
  hoverInfoData: Record<string, HoverInfo[][]>;
}

export const HFCellRenderer = (props: CustomCellRendererProps<any, any, HFCellRenderContext>) => {
  const { context, column, node } = props;
  const {
    sheetId,
    tab,
    rawSheetData,
    calculatedSheetData: sheetData,
    hoverInfoData,
  } = context as HFCellRenderContext;

  const rowIdx = node.rowIndex;
  const colId = column?.getColId() as string;
  const colIdx = excelColToColNumber(colId);
  const cellData: GridCellObject | undefined = node.data[colId];

  if (!cellData) {
    return null;
  }

  const hoverInfo = !rowIdx
    ? { type: "auto" }
    : (hoverInfoData?.[tab]?.[rowIdx]?.[colIdx] ?? { type: "auto" });

  const cellAddress: SimpleCellAddress = {
    sheet: sheetId,
    col: colIdx,
    row: rowIdx || 0,
  };

  const { formula, value } = {
    formula: rawSheetData?.[tab]?.[cellAddress.row]?.[colIdx],
    value: sheetData?.[tab]?.[cellAddress.row]?.[colIdx],
  };

  const cellDataType = cellData?.type;
  const startsWithEquals = typeof formula === "string" && formula.startsWith("=");

  const _toRender = cellDataType === "date" || !startsWithEquals ? formula : value;
  const toRender =
    _toRender === undefined
      ? ""
      : _toRender instanceof Date
        ? moment(_toRender).format("MM-DD-YYYY")
        : _toRender;
  const displayValue = getDisplayValue(toRender, cellDataType);

  const rowNumberForDisplay = typeof rowIdx === "number" && rowIdx + 1;

  if (colId === "A") {
    return (
      <div style={{ position: "relative" }}>
        <span
          style={{
            position: "absolute",
            left: "-0.5em",
            top: "50%",
            transform: "translateY(-50%)",
          }}
          className="text-xs font-light text-gray-600"
        >
          {rowNumberForDisplay}
        </span>
        <span id={`hf-value-${rowIdx}-${colId}`} className="text-base text-gray-900 ml-5">
          {displayValue}
        </span>
      </div>
    );
  }

  return (
    <span
      id={`hf-value-${rowIdx}-${colId}`}
      className="text-base text-gray-900 ml-5 w-full flex items-center justify-end"
      style={{
        paddingRight: hoverInfo.type === "text" ? "0" : "24px", // Add padding if info icon exists
      }}
    >
      {displayValue}
      {hoverInfo.type === "text" && (
        <HoverCard
          openDelay={250}
          withArrow
          arrowSize={20}
          position={"right"}
          styles={{
            dropdown: {
              width: "auto",
              padding: "20px 24px",
              borderRadius: "6px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              background: "#616F81",
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "17px",
              letterSpacing: "0.56px",
            },
          }}
        >
          <HoverCard.Target>
            <span className="ml-2 mt-1 text-primary-800 hover:text-[#D7D7D7]">
              <InfoIcon fontSize="inherit" />
            </span>
          </HoverCard.Target>
          <HoverCard.Dropdown>{(hoverInfo as HoverLabel).label}</HoverCard.Dropdown>
        </HoverCard>
      )}
    </span>
  );
};

export type GridCellObject = {
  value: RawCellContent;
  style: RowStyle;
  metadata?: RowMetadata;
  type: RowDataType;
  isManagedByApp: boolean;
};

const getDisplayValue = (cellValue: CellValue, cellType: RowDataType): React.ReactNode => {
  if (cellValue instanceof DetailedCellError) {
    return cellValue.value.toString();
  }

  if (cellValue === null || cellValue === undefined) {
    return "";
  }

  switch (cellType) {
    case "number":
      const num = cellValue as number;
      const maximumFractionDigits = num % 1 === 0 ? 0 : num < 1 ? 3 : 2;
      return cellValue.toLocaleString("en-US", { maximumFractionDigits, useGrouping: true });
    case "percentage":
      return `${((cellValue as number) * 100).toLocaleString("en-US", {
        maximumFractionDigits: 0,
      })}%`;
    case "date":
      const allowedTypes = ["number", "string", "boolean"];
      if (!allowedTypes.includes(typeof cellValue)) {
        return "";
      } else if (typeof cellValue === "number") {
        return moment(cellValue).format("MM-DD-YYYY");
      } else {
        return moment(cellValue as string).format("MM-DD-YYYY");
      }
    case "text":
    case "formula":
      return `${cellValue}`;
    case "ratio":
      return `${Number(cellValue).toFixed(2)}x`;
    default:
      return "";
  }
};
