import { RawCellContent } from "hyperformula";
import {
  FinancialItemComputed,
  FinancialItemType,
  FinancialPeriodItem,
  FinancialStatementItems,
} from "src/backend/services/ocr/abstractions/financial-statement";
import { GridRow } from "src/classes/GridState";
import { parseTaxCellAsFloatOrUndefined } from "src/backend/services/ocr/utils/parseTaxCellAsFloat";

const similarItem = (target: string, searchItem: string) => {
  // start with simple similarity check
  return target.toLowerCase().startsWith(searchItem.toLowerCase());
};

const getCellReference = (colIndex: number, rowIndex: number): string => {
  const colLetter = String.fromCharCode(65 + colIndex + 1);
  return `${colLetter}${rowIndex + 1}`;
};

export const convertToGridRows = (jsonData: FinancialStatementItems): GridRow[] => {
  let rowIndex = 0;

  const processItem = (
    item: FinancialPeriodItem,
    allPeriods: string[],
    levelIndex: number,
  ): GridRow[] => {
    const gridRows: GridRow[] = [];

    const skipItemLevelValues = item.total || (item.nestedItems && item.nestedItems.length > 0);
    const rowDataArray: RawCellContent[] = [
      item.name,
      ...allPeriods.map((period) => {
        if (skipItemLevelValues) {
          return "";
        } else {
          return parseTaxCellAsFloatOrUndefined(item.periodValues?.[period] || "");
        }
      }),
    ];
    const gridRow: GridRow = {
      rowDataArray,
      rowDataType: "number",
      rowStyle: levelIndex === 0 ? "highlighted" : "standard",
      rowMetadata: {
        levelIndex,
      },
      isManagedByApp: false,
      index: rowIndex++,
    };
    gridRows.push(gridRow);

    item.nestedItems?.forEach((nestedItem) => {
      gridRows.push(...processItem(nestedItem, allPeriods, levelIndex + 1));
    });

    if (item.total) {
      const totalRowDataArray: RawCellContent[] = [
        item.total.name,
        ...allPeriods.map((period) =>
          parseTaxCellAsFloatOrUndefined(item.total?.periodValues?.[period] || ""),
        ),
      ];

      const totalGridRow: GridRow = {
        rowDataArray: totalRowDataArray,
        rowDataType: "number",
        rowStyle: "highlighted",
        rowMetadata: {
          levelIndex: levelIndex + 1,
        },
        isManagedByApp: false,
        index: rowIndex++,
      };
      gridRows.push(totalGridRow);
    }

    return gridRows;
  };

  const gridRows: GridRow[] = [];
  const allPeriods = [...jsonData.availablePeriods];

  gridRows.push({
    rowDataArray: ["", ...allPeriods],
    rowDataType: "text",
    rowStyle: "highlighted",
    rowMetadata: {
      levelIndex: 0,
    },
    isManagedByApp: false,
    index: rowIndex++,
  });

  jsonData.items.forEach((item) => {
    gridRows.push(...processItem(item, allPeriods, 0));
  });

  // Compute fields from GridRows
  const computeBusinessCashFlowBeforeTaxRow = (rows: GridRow[]): GridRow | null => {
    let computed: GridRow | null = null;

    const netIncomeRows = rows
      .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.NetIncome))
      .filter((row) => row.rowDataArray.slice(1).some((cell) => cell))
      .map((row) => row.rowDataArray.map((_, colIndex) => getCellReference(colIndex, row.index)))
      .slice(0, 1);

    const depreciationRows = rows
      .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Depreciation))
      .map((row) => row.rowDataArray.map((_, colIndex) => getCellReference(colIndex, row.index)));

    const interestRows = rows
      .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Interest))
      .map((row) => row.rowDataArray.map((_, colIndex) => getCellReference(colIndex, row.index)));

    const amortizationRows = rows
      .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Amortization))
      .map((row) => row.rowDataArray.map((_, colIndex) => getCellReference(colIndex, row.index)));

    if (
      netIncomeRows.length > 0 ||
      depreciationRows.length > 0 ||
      interestRows.length > 0 ||
      amortizationRows.length > 0
    ) {
      const dataArray = allPeriods.map((_, colIndex) => {
        const netIncome = netIncomeRows.map((row) => row[colIndex]).join("+");
        const depreciation = depreciationRows
          .map((row) => row[colIndex])
          .filter((cell) => cell !== "")
          .join("+");
        const interest = interestRows
          .map((row) => row[colIndex])
          .filter((cell) => cell !== "")
          .join("+");
        const amortization = amortizationRows
          .map((row) => row[colIndex])
          .filter((cell) => cell !== "")
          .join("+");

        return `=${[netIncome, depreciation, interest, amortization].filter((s) => s).join("+")}`;
      });
      computed = {
        rowDataArray: [FinancialItemComputed.BusinessCashFlowBeforeTax, ...dataArray],
        rowDataType: "number",
        rowStyle: "highlighted",
        rowMetadata: {
          levelIndex: 0,
        },
        isManagedByApp: true,
        index: rowIndex++,
      };
    }
    return computed;
  };

  const businessCashFlowBeforeTaxRow = computeBusinessCashFlowBeforeTaxRow(gridRows);
  if (businessCashFlowBeforeTaxRow) {
    // Add empty row before showing computed fields - may need moving when we add more computed fields here
    gridRows.push({
      rowDataArray: ["", ...allPeriods.map(() => "")],
      rowDataType: "text",
      rowStyle: "standard",
      rowMetadata: {
        levelIndex: 0,
      },
      isManagedByApp: false,
      index: rowIndex++,
    });
    gridRows.push(businessCashFlowBeforeTaxRow);
  }

  return gridRows;
};
