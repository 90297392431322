import {
  IncomeAssetParts,
  PropertyTaxReturn,
} from "src/backend/services/ocr/abstractions/property";
import { PropertyWithYearsTable } from "src/classes/RenderedDocuments/PropertyWithYears";
import { NOIAnalysisYearRollup } from "src/classes/RenderedDocuments/NOIAnalysisYearRollup";
import { NOITaxReturnTotalTable } from "src/classes/RenderedDocuments/NOITaxReturnTotalTable";
import { RentRollTable, RentRollTableData } from "src/classes/RenderedDocuments/RentRoll";
import { RentRollTotalTable } from "src/classes/RenderedDocuments/RentRollTotal";
import { MAX_TAB_CHARACTERS } from "src/utils/constants";
import { formatNameForRentRoll, formatNameForSubject } from "src/classes/RenderedDocuments/helpers";
import { NOIAnalysisProps, TabName, TaxFormDataByYear } from "src/redux/reducers/types";
import { RenderedDoc } from "src/classes/RenderedDoc";
import { GridState } from "src/classes/GridState";
import { TaxProperty } from "src/interfaces/TaxFormData/schedules/ScheduleE";
import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { ColDef } from "ag-grid-community";
import { isDefined } from "src/backend/utils/tsutils";
import { LoanCalculatorData } from "src/classes/RenderedDocuments/LoanCalculatorRendered";
import { CanonicalRentRoll } from "src/models/CanonicalRentRoll";
import { canonicalRentRoll2RentRollData } from "src/classes/RenderedDocuments/canonicalRentRoll2RentRollData";
import { SupportedLenderId } from "src/interfaces/SpreadsConfig/SpreadsConfig";
import spreadConfig from "src/spreads-config";
import { TaxFormYear } from "src/interfaces/TaxFormData/TaxFormData";
import { spreadablePropertyLabel } from "src/redux/selectors/spreadablePropertyLabel";

type Year = string;

function sanitizePropertyName(propertyName: string): string {
  return propertyName.slice(0, Math.min(MAX_TAB_CHARACTERS, propertyName.length));
}

export class RenderedNOIAnalysis {
  rentRollTotalDocs: Record<string, RentRollTotalTable> = {};
  rentRollDocs: Record<string, RentRollTable> = {};
  subjectDocs: Record<string, RentRollTable> = {};
  subjectTotalDocs: Record<string, RentRollTotalTable> = {};
  taxReturnTotalDocs: Record<string, NOITaxReturnTotalTable> = {};
  taxReturnByYearDocs: Record<Year, NOIAnalysisYearRollup> = {};
  taxReturnByPropertyDocos: Record<string, PropertyWithYearsTable> = {};
  gridStates: Map<TabName, GridState> = new Map();
  loanCalculatorData: LoanCalculatorData | undefined;

  constructor(public historicalData: NOIAnalysisProps) {
    // Place 2b out of 3 to unwind 'useLLMForRentRoll'
    const config = spreadConfig.lenderSettings[historicalData.lenderId];
    const useLLMForRentRoll: boolean = config.useLLMForRentRoll;
    if (useLLMForRentRoll) {
      this.processCanonicalRentRolls(historicalData);
    } else {
      this.processLegacyRentRolls(historicalData);
    }
    this.processPersonalTaxReturns();
    this.processBusinessTaxReturns();
    this.processSubjectAssets(historicalData);

    this.gridStates = new Map();
    for (const [docName, doc] of Object.entries(this.getDocs())) {
      this.gridStates.set(docName as TabName, doc.gridState);
    }
  }

  private detectLenderId(): SupportedLenderId {
    const rrData = { ...this.historicalData.rentRolls, ...this.historicalData.legacyRentRolls };
    for (const year in rrData) {
      for (const rr of rrData[year]) {
        if (rr.extractContext.metadata.lenderId !== undefined) {
          return rr.extractContext.metadata.lenderId as SupportedLenderId;
        }
      }
    }

    const taxData = {
      ...this.historicalData.personalTaxReturns,
      ...this.historicalData.businessTaxReturns,
    };

    for (const year in taxData) {
      const taxForms = taxData[year as TaxFormYear];
      if (taxForms !== undefined) {
        for (const taxForm of taxForms) {
          if (taxForm.lenderId !== undefined) {
            return taxForm.lenderId as SupportedLenderId;
          }
        }
      }
    }

    return 0 as SupportedLenderId;
  }

  private processSubjectAssets(historicalData: NOIAnalysisProps): void {
    const subjectAssets = historicalData.subjectAssets;
    if (subjectAssets.length === 0) return;
    this.subjectDocs = subjectAssets.reduce(
      (acc, asset) => {
        const formattedSubjectName = formatNameForSubject(spreadablePropertyLabel(asset));
        acc[formattedSubjectName] = new RentRollTable(canonicalRentRoll2RentRollData(asset));
        return acc;
      },
      {} as Record<string, RentRollTable>,
    );

    if (Object.keys(this.subjectDocs).length > 1) {
      const formattedTotal = formatNameForSubject("Total");
      this.subjectTotalDocs[formattedTotal] = new RentRollTotalTable(
        subjectAssets.map(canonicalRentRoll2RentRollData),
        [],
      );
    }
  }

  /* Process Rent Rolls
   * ==================
   * Assumptions:
   * - Show only the most recent year
   * - Create "RR - Total" only if more than one property
   * - Excel errors desired if property is missing
   */

  /* Part 2a/3 to unwind 'useLLMForRentRoll' */
  private processLegacyRentRolls(historicalData: NOIAnalysisProps): void {
    // Filter out subject assets from rent rolls
    const inScopeRentRolls = Object.entries(historicalData.legacyRentRolls).reduce(
      (acc, [year, properties]) => {
        acc[year] = properties.filter((rentRoll) => {
          return !historicalData.legacySubjectAssets.some(
            (subjectAsset) => subjectAsset.propertyName === rentRoll.propertyName,
          );
        }) as RentRollTableData[];
        return acc;
      },
      {} as Record<string, RentRollTableData[]>,
    );
    if (Object.keys(inScopeRentRolls).length === 0) return;

    const years = Object.keys(inScopeRentRolls).map(Number);
    const mostRecentYear = Math.max(...years);
    const mostRecentYearString = mostRecentYear.toString();

    // Get most recent year's rent rolls
    const rentRollData = inScopeRentRolls[mostRecentYearString];
    const rentRolls = (
      (Array.isArray(rentRollData) ? rentRollData : [rentRollData]) as RentRollTableData[]
    ).filter(isDefined);

    if (rentRolls.length === 0) return;

    const formattedTotal = formatNameForRentRoll("Total");
    this.rentRollTotalDocs[formattedTotal] = new RentRollTotalTable(
      rentRolls,
      this.historicalData.legacySubjectAssets,
    );
    inScopeRentRolls[mostRecentYearString].forEach((item) => {
      if (item.propertyName === undefined) return;
      const rentRollData = item as RentRollTableData;
      const formattedPropertyName = formatNameForRentRoll(item.propertyName);
      this.rentRollDocs[formattedPropertyName] = new RentRollTable(rentRollData);
    });
  }

  private processCanonicalRentRolls(historicalData: NOIAnalysisProps): void {
    // Filter out subject assets from rent rolls
    const inScopeRentRolls = Object.entries(historicalData.rentRolls).reduce(
      (acc, [year, properties]) => {
        acc[year] = properties.filter((rentRoll) => {
          return !historicalData.subjectAssets.some(
            (subjectAsset) =>
              spreadablePropertyLabel(subjectAsset) === spreadablePropertyLabel(rentRoll),
          );
        }) as CanonicalRentRoll[];
        return acc;
      },
      {} as Record<string, CanonicalRentRoll[]>,
    );
    if (Object.keys(inScopeRentRolls).length === 0) return;

    const years = Object.keys(inScopeRentRolls).map(Number);
    const mostRecentYear = Math.max(...years);
    const mostRecentYearString = mostRecentYear.toString();

    // Get most recent year's rent rolls
    const rentRollData = inScopeRentRolls[mostRecentYearString];
    const rentRolls = (
      (Array.isArray(rentRollData) ? rentRollData : [rentRollData]) as CanonicalRentRoll[]
    ).filter(isDefined);

    if (rentRolls.length === 0) return;

    if (rentRolls.length > 1) {
      const formattedTotal = formatNameForRentRoll("Total");
      this.rentRollTotalDocs[formattedTotal] = new RentRollTotalTable(
        rentRolls.map(canonicalRentRoll2RentRollData),
        historicalData.subjectAssets.map(canonicalRentRoll2RentRollData),
      );
    }
    inScopeRentRolls[mostRecentYearString].forEach((item) => {
      const rentRollData = item as CanonicalRentRoll;
      const formattedPropertyName = formatNameForRentRoll(spreadablePropertyLabel(rentRollData));
      this.rentRollDocs[formattedPropertyName] = RentRollTable.from(rentRollData);
    });
  }

  /* Process Personal Tax returns
   * ============================
   * Assumptions:
   * - Show all years
   * - Create "PTR - Total" and yearly breakdowns (i.e. "PTR - 2023") only if more than one property
   * - Excel errors desired if property or yearly data is missing
   */

  public formatNameForPersonalTaxReturn(name: string): string {
    return sanitizePropertyName(`PTR - ${name}`);
  }

  public formatNameForBusinessTaxReturn(name: string): string {
    return sanitizePropertyName(`BTR - ${name}`);
  }

  coveredYearsForPersonalTaxReturns(): Year[] {
    return Object.keys(this.taxReturnByYearDocs);
  }

  // Creates, e.g. "PTR - 2023", where each column references a particular property
  private processPersonalTaxReturnsByYear(personalTaxReturns: TaxFormDataByYear<Form1040>): void {
    for (const [year, forms] of Object.entries(personalTaxReturns)) {
      forms?.forEach((form) => {
        const yearColumn = Object.keys(personalTaxReturns).indexOf(year) + 2;
        this.taxReturnByYearDocs[`${this.formatNameForPersonalTaxReturn(year.toString())}`] =
          new NOIAnalysisYearRollup(
            year,
            (form?.schedules?.scheduleE?.properties || []).map((property) => ({
              ...property,
              form: form.form,
            })),
            yearColumn,
            this.formatNameForPersonalTaxReturn,
          );
      });
    }
  }

  private processBusinessTaxReturnsByYear(
    businessTaxReturns: TaxFormDataByYear<Form1065 | Form1120S>,
  ): void {
    for (const [year, forms] of Object.entries(businessTaxReturns)) {
      const yearColumn = Object.keys(businessTaxReturns).indexOf(year) + 2;
      const properties = forms
        .map((form) => form.form8825?.propertyData)
        .flat()
        .filter((property) => property !== undefined)
        .flat() as TaxProperty[];
      this.taxReturnByYearDocs[`${this.formatNameForBusinessTaxReturn(year.toString())}`] =
        new NOIAnalysisYearRollup(
          year,
          properties,
          yearColumn,
          this.formatNameForBusinessTaxReturn,
        );
    }
  }

  private processBusinessTaxReturnsByProperty(
    businessTaxReturns: TaxFormDataByYear<Form1065 | Form1120S>,
  ): void {
    const byPropertyByYear: Record<string, Record<string, TaxProperty>> = {};
    const propertyToEntity: Record<string, string> = {};

    for (const [year, forms] of Object.entries(businessTaxReturns)) {
      forms?.forEach((form, _index) => {
        const formType = form.form;
        const entityName = form.entityName;
        form.form8825?.propertyData?.forEach((property) => {
          propertyToEntity[property.propertyAddress] = entityName;

          const propertyName = property.propertyAddress?.toString();
          if (!propertyName) {
            throw new Error("Property name is missing");
          }

          const formatted = this.formatNameForBusinessTaxReturn(propertyName);

          if (!byPropertyByYear[formatted]) {
            byPropertyByYear[formatted] = {};
          }

          byPropertyByYear[formatted][year] = { form: formType, ...property };
        });
      });
    }

    // Now that we have a property-centric view, we can create PropertyByYearDoc objects
    for (const [tabName, yearlyData] of Object.entries(byPropertyByYear)) {
      const formatted = sanitizePropertyName(tabName);
      const propertyAddresses = Object.values(yearlyData).map(
        (property) => property.propertyAddress,
      );
      //make sure all properties have the same address
      if (propertyAddresses.some((address) => address !== propertyAddresses[0])) {
        throw new Error("All properties must have the same address for a by-year document");
      }
      const propertyAddress = propertyAddresses[0];
      const entityName = propertyToEntity[propertyAddress];
      const doc = new PropertyWithYearsTable(propertyAddress, yearlyData, entityName);
      this.taxReturnByPropertyDocos[formatted] = doc;
    }
  }

  // Creates, e.g. "PTR - 12 Ford St" and "PTR - 20 Acacia Ave"
  private processPersonalTaxReturnsByProperty(
    personalTaxReturns: TaxFormDataByYear<Form1040>,
  ): void {
    // Transforming the data structure to be property-centric rather than year-centric
    const byPropertyByYear: Record<string, Record<string, TaxProperty>> = {};
    const propertyToEntity: Record<string, string> = {};
    const propertyToLender: Record<string, number> = {};

    for (const [year, forms] of Object.entries(personalTaxReturns)) {
      forms?.forEach((form) => {
        const { entityName, lenderId } = form;
        form.schedules?.scheduleE?.properties?.forEach((property) => {
          propertyToEntity[property.propertyAddress] = entityName;
          propertyToLender[property.propertyAddress] = lenderId;

          const propertyName = property.propertyAddress?.toString();
          if (!propertyName) {
            throw new Error("Property name is missing");
          }

          const formatted = this.formatNameForPersonalTaxReturn(propertyName);

          if (!byPropertyByYear[formatted]) {
            byPropertyByYear[formatted] = {};
          }

          byPropertyByYear[formatted][year] = { ...property, form: form.form };
        });
      });
    }

    // Now that we have a property-centric view, we can create PropertyByYearDoc objects
    for (const [tabName, yearlyData] of Object.entries(byPropertyByYear)) {
      const formatted = sanitizePropertyName(tabName);
      const propertyAddresses = Object.values(yearlyData).map(
        (property) => property.propertyAddress,
      );
      //make sure all properties have the same address
      if (propertyAddresses.some((address) => address !== propertyAddresses[0])) {
        throw new Error("All properties must have the same address for a by-year document");
      }
      const propertyAddress = propertyAddresses[0];
      const entityName = propertyToEntity[propertyAddress];
      const lenderId = propertyToLender[propertyAddress];
      const asIncomeAssetParts = Object.entries(yearlyData).map(([year, property]) => {
        return [
          year,
          {
            ...PropertyTaxReturn.from(property, property.propertyAddress, lenderId),
            form: property.form,
          },
        ];
      });
      const data = Object.fromEntries(asIncomeAssetParts);
      const doc = new PropertyWithYearsTable(propertyAddress, data, entityName);
      this.taxReturnByPropertyDocos[formatted] = doc;
    }
  }

  private processBusinessTaxReturns(): void {
    const allBusinessTaxReturns: TaxFormDataByYear<Form1065 | Form1120S> =
      this.historicalData.businessTaxReturns;
    const properties = Object.values(allBusinessTaxReturns)
      .flatMap((forms) => forms.map((form) => form.form8825?.propertyData ?? []))
      .flat();
    if (properties.length === 0) return;

    // 1 - Get Total from yearly summary sheet for most recent year
    const formattedTotal = this.formatNameForBusinessTaxReturn("Total");
    const numberProperties = properties.length;

    const totalBusinessTaxReturn = new NOITaxReturnTotalTable(
      allBusinessTaxReturns,
      this.formatNameForBusinessTaxReturn, // For referencing the other (yearly) sheet by its tab
      numberProperties, // For determining the number of columns to sum in the other (yearly) sheet
    );
    this.taxReturnTotalDocs[formattedTotal] = totalBusinessTaxReturn;
    // Process yearly summaries, e.g. "BTR - 2023", "BTR -2022", etc.
    this.processBusinessTaxReturnsByYear(allBusinessTaxReturns);
    // Process property-specific docs, e.g. "BTR - 12 Ford St", "BTR - 20 Acacia Ave", etc.
    this.processBusinessTaxReturnsByProperty(allBusinessTaxReturns);
  }

  // Bind above function to create Total, Yearly Summaries, and Property-specific docs
  private processPersonalTaxReturns(): void {
    const allPersonalTaxReturns = this.historicalData.personalTaxReturns;
    const properties = Object.values(allPersonalTaxReturns)
      .flatMap((forms) => forms.map((form) => form.schedules?.scheduleE?.properties ?? []))
      .flat();
    if (properties.length === 0) return;

    const formattedTotal = this.formatNameForPersonalTaxReturn("Total");
    const numberProperties = properties.length;

    const totalPersonalTaxReturn = new NOITaxReturnTotalTable(
      allPersonalTaxReturns,
      this.formatNameForPersonalTaxReturn, // For referencing the other (yearly) sheet by its tab
      numberProperties, // For determining the number of columns to sum in the other (yearly) sheet
    );
    this.taxReturnTotalDocs[formattedTotal] = totalPersonalTaxReturn;
    // Process yearly summaries, e.g. "PTR - 2023", "PTR -2022", etc.
    this.processPersonalTaxReturnsByYear(allPersonalTaxReturns);
    // Process property-specific docs, e.g. "PTR - 12 Ford St", "PTR - 20 Acacia Ave", etc.
    this.processPersonalTaxReturnsByProperty(allPersonalTaxReturns);
  }

  public getDocs(): Record<TabName, RenderedDoc> {
    return {
      ...this.rentRollTotalDocs,
      ...this.rentRollDocs,
      ...this.taxReturnTotalDocs,
      ...this.taxReturnByYearDocs,
      ...this.taxReturnByPropertyDocos,
      ...this.subjectDocs,
      ...this.subjectTotalDocs,
    };
  }

  public columnDefs(): Record<TabName, ColDef[]> {
    return Object.entries(this.getDocs()).reduce(
      (acc, [key, value]) => {
        return { ...acc, [key]: value.columnDefs };
      },
      {} as Record<TabName, ColDef[]>,
    );
  }
}
