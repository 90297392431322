import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";

export const Form1120sBalanceSheetRowHeadings = {
  Assets: "Assets",
  Cash: "Cash",
  AccountsAndNotesReceivable: "Accounts and notes receivable (current), net",
  AccountsAndNotesReceivableGrossAmount: "Accounts and notes receivable, gross amount",
  AllowanceForBadDebts: "Less: Allowance for bad debts",
  Inventory: "Inventory",
  MarketableSecurities: "Marketable securities",
  UsGovernmentObligations: "U.S. government obligations",
  TaxExemptSecurities: "Tax-exempt securities",
  OtherCurrentAssets: "Other current assets",
  TotalCurrentAssets: "Total current assets",
  NotesReceivable: "Notes receivable (non-current)",
  LoansToShareholders: "Loans to shareholders",
  OtherInvestments: "Other investments",
  LandNetOfAnyAmortization: "Land (net of any amortization)",
  BuildingsAndOtherDepreciableAssets: "Buildings and other depreciable assets, net of depreciation",
  DepletableAssets: "Depletable assets, net",
  IntangibleAssets: "Intangible assets, net",
  OtherAssets: "Other assets",
  TotalNonCurrentAssets: "Total non-current assets",
  TotalAssets: "Total assets",
  LiabilitiesAndShareholdersEquity: "Liabilities and shareholders' equity",
  AccountsPayable: "Accounts payable",
  ShortTermDebt: "Short term debt and current maturities of long term debt",
  OtherCurrentLiabilities: "Other current liabilities",
  TotalCurrentLiabilities: "Total current liabilities",
  LongTermDebt: "Long term debt",
  LoansFromShareholders: "Loans from shareholders",
  OtherNonCurrentLiabilities: "Other non-current liabilities",
  TotalNonCurrentLiabilities: "Total non-current liabilities",
  TotalLiabilities: "Total liabilities",
  CapitalStock: "Capital stock",
  AdditionalPaidInCapital: "Additional paid-in capital",
  RetainedEarnings: "Retained earnings",
  AdjustmentsToShareholdersEquity: "Adjustments to shareholders' equity",
  LessTreasuryStock: "Less: Treasury stock",
  TotalShareholdersEquity: "Total shareholders' equity",
  TotalLiabilitiesAndEquity: "Total liabilities and shareholders' equity",
};

export function build1120sBalanceSheet(
  data: Form1120S,
  startingColumn: string,
  startingRow: number,
) {
  return new AutoRenderedSheetBuilder(
    data,
    Form1120sBalanceSheetRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRow(({ labels }) => [labels.Assets, ""])
    .addRow(({ data, labels }) => [
      labels.Cash,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.cash?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.AccountsAndNotesReceivable,
      (data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.tradeNotesAndAccountsReceivable
        ?.endOfTaxYear ?? 0) -
        Math.abs(
          data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.lessAllowanceForBadDebts
            ?.endOfTaxYear ?? 0,
        ),
    ])
    .addRow(({ data, labels }) => [
      labels.Inventory,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.inventories?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.MarketableSecurities,
      (data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.usGovernmentObligations
        ?.endOfTaxYear ?? 0) +
        (data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.taxExemptSecurities
          ?.endOfTaxYear ?? 0),
    ])
    .addRow(({ data, labels }) => [
      labels.OtherCurrentAssets,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.otherCurrentAssets?.endOfTaxYear ??
        0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalCurrentAssets,
      `=${columnReference(labels.Cash)} + ${columnReference(labels.AccountsAndNotesReceivable)} + ${columnReference(labels.Inventory)} + ${columnReference(labels.MarketableSecurities)} + ${columnReference(labels.OtherCurrentAssets)}`,
    ])

    .addRow(({ data, labels }) => [
      labels.NotesReceivable,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.mortgageAndRealEstateLoans
        ?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.LoansToShareholders,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.loansToShareholdersOrPartners
        ?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.OtherInvestments,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.otherInvestments?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.LandNetOfAnyAmortization,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.landNetOfAnyAmortization
        ?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.BuildingsAndOtherDepreciableAssets,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets
        ?.buildingsAndOtherDepreciableAssetsNetOfAccumulatedDepreciation?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.DepletableAssets,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets
        ?.depletableAssetsNetOfAccumulatedDepletion?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.IntangibleAssets,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets
        ?.intangibleAssetsNetOfAccumulatedAmortization?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.OtherAssets,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets?.otherAssets?.endOfTaxYear ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalNonCurrentAssets,
      `=${columnReference(labels.NotesReceivable)} + ${columnReference(labels.LoansToShareholders)} + ${columnReference(labels.OtherInvestments)} + ${columnReference(labels.LandNetOfAnyAmortization)} + ${columnReference(labels.BuildingsAndOtherDepreciableAssets)} + ${columnReference(labels.DepletableAssets)} + ${columnReference(labels.IntangibleAssets)} + ${columnReference(labels.OtherAssets)}`,
    ])

    .addRow(({ labels, columnReference }) => [
      labels.TotalAssets,
      `=${columnReference(labels.TotalCurrentAssets)} + ${columnReference(labels.TotalNonCurrentAssets)}`,
    ])

    .addRow(({ labels }) => [labels.LiabilitiesAndShareholdersEquity, ""])
    .addRow(({ data, labels }) => [
      labels.AccountsPayable,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity?.accountsPayable
        ?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.ShortTermDebt,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
        ?.mortgagesNotesAndBondsPayableLessThanOneYear?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.OtherCurrentLiabilities,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
        ?.otherCurrentLiabilities?.endOfTaxYear ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalCurrentLiabilities,
      `=${columnReference(labels.AccountsPayable)} + ${columnReference(labels.ShortTermDebt)} + ${columnReference(labels.OtherCurrentLiabilities)}`,
    ])

    .addRow(({ data, labels }) => [
      labels.LongTermDebt,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
        ?.mortgagesNotesAndBondsPayableMoreThanOneYear?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.LoansFromShareholders,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
        ?.loansFromShareholdersOrPartners?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.OtherNonCurrentLiabilities,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity?.otherLiabilities
        ?.endOfTaxYear ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalNonCurrentLiabilities,
      `=${columnReference(labels.LongTermDebt)} + ${columnReference(labels.LoansFromShareholders)} + ${columnReference(labels.OtherNonCurrentLiabilities)}`,
    ])

    .addRow(({ labels, columnReference }) => [
      labels.TotalLiabilities,
      `=${columnReference(labels.TotalCurrentLiabilities)} + ${columnReference(labels.TotalNonCurrentLiabilities)}`,
    ])

    .addRow(({ data, labels }) => [
      labels.CapitalStock,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
        ?.capitalStockOrPartnersCapital?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.AdditionalPaidInCapital,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
        ?.additionalPaidInCapital?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.RetainedEarnings,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity?.retainedEarnings
        ?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.AdjustmentsToShareholdersEquity,
      data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity
        ?.adjustmentsToShareholdersEquity?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.LessTreasuryStock,
      Math.abs(
        data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity?.lessTreasuryStock
          ?.endOfTaxYear ?? 0,
      ),
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalShareholdersEquity,
      `=${columnReference(labels.CapitalStock)} + ${columnReference(labels.AdditionalPaidInCapital)} + ${columnReference(labels.RetainedEarnings)} + ${columnReference(labels.AdjustmentsToShareholdersEquity)} - ${columnReference(labels.LessTreasuryStock)}`,
    ])
    .addRow()
    .addRow(({ labels, columnReference }) => [
      labels.TotalLiabilitiesAndEquity,
      `=${columnReference(labels.TotalLiabilities)} + ${columnReference(labels.TotalShareholdersEquity)}`,
    ]);
}
