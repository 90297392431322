import { createAction } from "@reduxjs/toolkit";
import { DocumentAction } from "src/redux/actions/actions.constants";
import { DocumentTypeEnum } from "src/Enums/DocumentTypeEnum";

export type FileStructureAndDocumentUploadIds = {
  fileStructure: string[];
  documentUploadId: number;
}[];

export interface DownloadAllDocumentsPayload {
  fileStructureAndDocumentUploadIds: FileStructureAndDocumentUploadIds;
}

export const downloadAllDocuments = createAction<DownloadAllDocumentsPayload>(
  DocumentAction.DOWNLOAD_ALL_DOCUMENTS,
);

export const downloadAllDocumentsCompleted = createAction(
  DocumentAction.DOWNLOAD_ALL_DOCUMENTS_COMPLETED,
);

export const downloadAllDocumentsFailed = createAction<Error>(
  DocumentAction.DOWNLOAD_ALL_DOCUMENTS_FAILED,
);
