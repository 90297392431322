import { DocumentAction } from "src/redux/actions/actions.constants";
import { Reducer } from "redux";

export interface DocumentsState {
  loading: boolean;
  error: any;
}

const initialState: DocumentsState = {
  loading: false,
  error: null,
};

export const documentsReducer: Reducer<DocumentsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case DocumentAction.DOWNLOAD_ALL_DOCUMENTS:
      return { ...state, loading: true, error: null };
    case DocumentAction.DOWNLOAD_ALL_DOCUMENTS_COMPLETED:
      return { ...state, loading: false };
    case DocumentAction.DOWNLOAD_ALL_DOCUMENTS_FAILED:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
