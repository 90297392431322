import { call, put, takeLatest } from "redux-saga/effects";
import { DocumentAction } from "src/redux/actions/actions.constants";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  downloadAllDocuments,
  downloadAllDocumentsCompleted,
  downloadAllDocumentsFailed,
} from "src/redux/actions/documents.action";

function* handleDownloadDocuments(action: ReturnType<typeof downloadAllDocuments>) {
  try {
    const { fileStructureAndDocumentUploadIds } = action.payload;

    const zip = new JSZip();
    let topLevelDirectoryName: string = "Documents";
    for (const fileStructureAndDocumentUploadId of fileStructureAndDocumentUploadIds) {
      const fileUrl = `/api/document/${fileStructureAndDocumentUploadId.documentUploadId}/download-preview`;
      const response: Response = yield call(fetch, fileUrl);
      const fileData: Blob = yield call([response, response.blob]);
      topLevelDirectoryName =
        fileStructureAndDocumentUploadId.fileStructure.shift() ?? topLevelDirectoryName;
      zip.file(`/${fileStructureAndDocumentUploadId.fileStructure.join("/")}`, fileData, {
        createFolders: true,
      });
    }

    const zipFile: Blob = yield zip.generateAsync({ type: "blob" });
    saveAs(zipFile, `${topLevelDirectoryName}.zip`);

    yield put(downloadAllDocumentsCompleted());
  } catch (error) {
    yield put(downloadAllDocumentsFailed(new Error(`${error}`)));
  }
}

function* documents() {
  yield takeLatest(DocumentAction.DOWNLOAD_ALL_DOCUMENTS, handleDownloadDocuments);
}

export default documents;
